import React from "react"

import LinkExternal from "../components/link_external"
import Seo from "../components/seo"
import Layout from "../components/layout"
import HighlightVideos from "../components/highlight_videos"
import { useImpression } from "../analytics/utils"

const sections = [
  {
    name: "Articles",
    items: [
      {
        to:
          "https://www.psychologytoday.com/us/blog/our-humanity-naturally/201302/why-sometimes-religion-should-be-criticized",
        title: "Why Sometimes Religion Should be Criticized",
      },
      {
        to:
          "https://www.relativelyinteresting.com/questions-for-theists-god-and-religion/",
        title: "Questions for Theists, God, and Religion",
      },
    ],
  },
  {
    name: "Miscellaneous",
    items: [
      {
        to: "https://plato.stanford.edu/entries/atheism-agnosticism/",
        title: "Stanford Encyclopedia of Philosophy - Atheism and Agnosticism",
      },
      {
        to: "https://www.dictionary.com/e/atheism-agnosticism/",
        title: "Atheism vs. Agnosticism",
      },
      {
        to: "http://www.religionresourcesonline.org/",
        title: "Religion Resources Online",
      },
      {
        to:
          "https://en.wikipedia.org/wiki/List_of_countries_by_irreligion#cite_note-Demographics_Oxford_Keysar-6",
        title: "List of countries by irreligion",
      },
      {
        to: "https://en.wikipedia.org/wiki/The_Age_of_Reason",
        title: "The Age of Reason - Thomas Paine",
      },
    ],
  },
  {
    name: "Research",
    items: [
      {
        to:
          "https://www.pewforum.org/religious-landscape-study/religious-family/atheist/",
        title: "Religious Landscape Study of Atheism (Pew)",
      },
      {
        to:
          "https://www.pewforum.org/2012/12/18/global-religious-landscape-exec/",
        title: "The Global Religious Landscape (Pew)",
      },
      {
        to:
          "https://www.pewforum.org/2015/11/03/chapter-1-importance-of-religion-and-religious-beliefs/",
        title: "The Importance of Religion and Religious Beliefs (Pew)",
      },
    ],
  },
  {
    name: "Helpful Resources",
    items: [
      {
        to:
          "https://www.psychologytoday.com/us/blog/our-humanity-naturally/201302/why-sometimes-religion-should-be-criticized",
        title: "Why Sometimes Religion Should be Criticized",
      },
      {
        to: "https://www.recoveringfromreligion.org/religious-resources",
        title: "Recovering from Religion Resources",
      },
    ],
  },
  {
    name: "Shows and Podcasts",
    items: [
      {
        to: "http://www.atheist-experience.com/",
        title: "The Atheist Experience",
      },
    ],
  },
]

const Resources = ({ location }) => {
  useImpression("Resources")
  return (
    <Layout location={location}>
      <Seo
        title="Religion and Atheism Resources"
        description="Find more religion, atheism, agnostic, and other resources and keep learning."
      />
      <h1>Religion and Atheism Resources</h1>
      <p>
        Here are some great resources to learn more about religion, atheism,
        agnostic, and more.
      </p>
      {sections.map(({ name, items }) => (
        <div key={name}>
          <h2>{name}</h2>
          <ul>
            {items.map(({ title, to }) => (
              <li key={title}>
                <LinkExternal to={to}>{title}</LinkExternal>
              </li>
            ))}
          </ul>
        </div>
      ))}
      <HighlightVideos />
    </Layout>
  )
}

export default Resources
