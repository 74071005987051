import React from "react"
import Video from "./video"

const HighlightVideos = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    }}
  >
    <div style={{ padding: 10 }}>
      <Video
        controls
        playing
        url="https://www.youtube.com/watch?v=rYnjDCZUV6A"
      />
    </div>
    <div style={{ padding: 10 }}>
      <Video controls url="https://www.youtube.com/watch?v=94ijMUVOK6k" />
    </div>
    <div style={{ padding: 10 }}>
      <Video controls url="https://www.youtube.com/watch?v=3d8NthEFWow" />
    </div>
    <div style={{ padding: 10 }}>
      <Video controls url="https://www.youtube.com/watch?v=m6dCxo7t_aE" />
    </div>
    <div style={{ padding: 10 }}>
      <Video controls url="https://www.youtube.com/watch?v=EHBAyP2wjic" />
    </div>
  </div>
)

export default HighlightVideos
